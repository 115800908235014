import React from 'react';
import styled from 'styled-components'
import {Link} from "gatsby";


const FullSite = styled.div`
width: 100vw;
height: 100%;
min-height: 100vh;
background: rgb(13,31,64);
            background: linear-gradient( rgba(13,31,64,1) 0%, rgba(7,43,110,1) 52%, rgba(13,31,64,1) 100%);
`

const TopMenu = styled.div`
height: 50px;

`
const BackArrow = styled.img`
width: 50px;
height: 50px;
margin: 20px 20px;
`

const Logo = styled.img`
height: 35px;
position: absolute;
margin-left: auto;
margin-right: auto;
text-align: center;
left: 0;
right: 0;
top: 25px;

`

const MainContent = styled.div`
margin: 60px 20vw 0 30vw;
padding-bottom: 100px;
@media (max-width:600px) {
margin: 60px 7vw 0 12vw;
            }

`
const Header = styled.h1`
color: #FFA500;
margin-bottom: 30px;
`

const Header3 = styled.h3`
color: #ffffff;
margin-bottom: 20px;
margin-top: 10px;
`

const Paragraph = styled.p`
color: #ffffff;

`

const Shareholders = () => {
    return (
        <FullSite>
            <TopMenu>
                <Link to="/"><BackArrow src="/icons/back.svg"/></Link>
                <Logo src="/img/logo_light_rgb.png" />
            </TopMenu>
            <MainContent>
                <Header>Investor Relations</Header>
                <Paragraph>InPay S.A.<br/>
                ul. Sienna 86/47 00-815 Warszawa</Paragraph>
                <Paragraph>NIP: 7010430929, REGON: 147302566<br/>
                KRS: 0000512707 Sąd Rejonowy Warszawa XII Wydział Gospodarczy</Paragraph>
                <Header3>Akcjonariat</Header3>
                <Paragraph>Kapitał zakładowy: 100.000 zł
                <br/>
                Kapitał docelowy: 75.000 zł
                <br/>
                Kapitał opłacony: 100.000 zł</Paragraph>
                <Header3>Dematerializacja akcji</Header3>
                <Paragraph>W skutek wejścia w życie ustawy dnia 30 sierpnia 2019 r. o zmianie ustawy -
                    Kodeks spółek handlowych oraz niektórych innych ustaw (Dz.U. z 2019 r. poz. 1798) najpóźniej do 31.12.2020 r.
                    należy złożyć w spółce wszystkie papierowe dokumenty własności akcji aby mogły być przekształcone w formę elektroniczną.
                    Moc obowiązująca dokumentów akcji wydanych przez spółkę wygasa z mocy prawa z dniem 1 stycznia 2021 r.</Paragraph>
                <Header3>Rejestr akcjonariuszy</Header3>
                <Header3>Walne Zgromadzenia / ogłoszenia</Header3>
                <a href="https://www.imsig.pl/pozycja/2020/172/44296,INPAY_SP%C3%93%C5%81KA_AKCYJNA" target="_blank">Numer 172/2020 (6062) z 3 września 2020 r.</a>
                <br/>
                <br/>
                <Paragraph>
                Zarząd InPay S.A. z siedzibą w Warszawie wzywa wszystkich akcjonariuszy Spółki do
                złożenia w Spółce dokumentów akcji w celu ich dematerializacji, w terminie do dnia
                31.12.2020 r.<br/>
                Dokumenty akcji należy składać w siedzibie Spółki, tj. ul. Grzybowska 4 lok. 106,
                00-131 Warszawa, od poniedziałku do piątku w godzinach 10-16.
                Niniejsze wezwanie jest pierwszym z pięciu wezwań.
                </Paragraph>


            </MainContent>
        </FullSite>
    );
};

export default Shareholders;